<template>
  <v-container>
    <h2 class="mb-6 text-h1">
      {{ $t('Projects') }}
    </h2>

    <projects-list-business v-if="profileLoaded && profile.accountType === 'business'" />
    <projects-list-individual v-if="profileLoaded && !profile.accountType" />
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import ProjectsListBusiness from '@/components/ProjectsListBusiness'
import ProjectsListIndividual from '@/components/ProjectsListIndividual'

export default {
  name: 'ProjectsList',
  components: {ProjectsListIndividual, ProjectsListBusiness},
  computed: mapState({
    profileLoaded: state => state.loaders.profile.status,
    profile: state => state.profile,
  }),
}
</script>
