<template>
  <div>
    <v-tooltip
      v-if="deposit.status === 'draft' && !readyToRegister"
      open-delay="150"
      offset-overflow
      bottom
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
          :ripple="false"
          :to="depositUrl"
          v-bind="attrs"
          block
          class="button-not-ready"
          rounded
          outlined
          v-on="on"
        >
          {{ $t('Not ready') }}
        </v-btn>
      </template>
      <div>
        <div
          v-for="(reason, i) in reasonsNotReady"
          :key="`${deposit.id}-reason-${i}`"
        >
          {{ reason }}
        </div>
      </div>
    </v-tooltip>
    <v-btn
      v-if="(deposit.status === 'draft' && readyToRegister) || deposit.status === 'locked'"
      :loading="registering"
      :ripple="false"
      class="btn-bg-gradient"
      color="primary"
      data-cy="button-register"
      elevation="0"
      block
      rounded
      @click="register"
    >
      {{ $t('Register') }}
    </v-btn>
    <v-btn
      v-if="deposit.status === 'in_progress'"
      :ripple="false"
      data-cy="button-in-progress"
      class="button-in-progress"
      color="primary"
      block
      outlined
      rounded
    >
      {{ $t('In progress') }}
    </v-btn>
    <v-btn
      v-if="deposit.status === 'registered'"
      :ripple="false"
      class="button-in-progress"
      color="success"
      elevation="0"
      block
      outlined
      rounded
    >
      {{ $t('Registered') }}
    </v-btn>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {mdiHelpCircle} from '@mdi/js'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'DepositRowRegistration',
  mixins: [error401handler],
  props: {
    deposit: {type: Object, required: true}
  },
  data () {
    return {
      registering: false,
      icons: {
        help: mdiHelpCircle
      }
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
    }),
    readyToRegister () {
      return this.reasonsNotReady.length === 0
    },
    reasonsNotReady () {
      let reasons = []
      if (!this.deposit.name) {
        reasons.push(this.$t('Deposit title has to be filled'))
      }
      if (!this.deposit.description) {
        reasons.push(this.$t('Deposit description has to be filled'))
      }
      if (
        ['algorithm', 'implementation', 'project'].includes(this.deposit.type) &&
        !this.deposit.content
      ) {
        reasons.push(this.$t('Deposit content has to be filled'))
      }
      if (this.deposit.type === 'screens' && !this.deposit.files.length) {
        reasons.push(this.$t('Deposit must have at least one screenshot'))
      }
      return reasons
    },
    depositUrl () {
      return {
        name: 'deposit-details',
        params: {
          depositId: this.deposit.id,
          projectId: this.$route.params.projectId
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show',
    }),
    ...mapActions({
      registerDepositPrv: 'deposits/dpRegister',
      registerDepositBiz: 'depositsBusiness/dpRegister',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    registerDeposit () {
      return (this.isBusinessAccount ? this.registerDepositBiz : this.registerDepositPrv)(...arguments)
    },
    async register () {
      this.registering = true
      try {
        await this.registerDeposit(this.deposit.id)
        this.showNotify({
          text: this.deposit.status === 'registered'
            ? this.$t('Deposit registered')
            : this.$t('Registration of deposit started'),
          color: 'success'
        })
      } catch (e) {
        this.handleError(e, {
          mode: 'modal',
          title: this.$t('Unable register deposit')
        })
      }
      this.registering = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-in-progress {
    cursor: default;
  }

  .theme--light.button-in-progress.v-btn:hover::before {
    opacity: 0;
  }

  .theme--light.button-not-ready.v-btn {
    color: rgba(0,0,0,0.26);
  }
</style>
