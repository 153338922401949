<template>
  <v-container class="deposit-container pb-0 pb-sm-3">
    <v-card
      :ripple="false"
      class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
      flat
      min-height="300"
      outlined
    >
      <v-overlay :value="loading" opacity="0.1" absolute>
        <v-progress-circular color="primary" indeterminate />
      </v-overlay>

      <template v-if="!loading">
        <!-- manual -->
        <!--
        <v-row>
          <v-col cols="12" md="6">
            <h4 class="mb-3">
              {{ $t('Manually added dependencies') }}
              <v-chip v-if="loadingManual">
                <v-progress-circular color="grey" indeterminate size="20" width="3" />
              </v-chip>
              <v-chip v-else-if="licensesManual.length">
                {{ licensesManual.length }}
              </v-chip>
            </h4>
            <v-list v-if="licensesManual.length">
              <ProjectLibLicenseManual
                v-for="lib in licensesManual"
                :key="`lib-${lib.id}`"
                :lib="lib"
                @stop="stopTracking"
              />
            </v-list>
            <div v-else class="text--secondary">
              {{ $t('No dependencies provided') }}
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <h4 class="mb-3">
              {{ $t('Manual dependencies add') }}
            </h4>
            <v-form ref="formManual" @submit.prevent>
              <v-textarea
                v-model="libs"
                :disabled="savingManual"
                :error-messages="serverFeedback.libs"
                :hint="$t('One library per row')"
                :label="$t('New dependencies')"
                :rules="rules.libs"
                outlined
              >
                <template v-slot:append-outer>
                  <div class="ms-4">
                    <div class="text-caption mb-2">
                      {{ $t('Только с github.com, например') }}:
                    </div>
                    <div class="text--secondary text-body-2 text-no-wrap">
                      numpy/numpy<br>
                      pandas-dev/pandas<br>
                      jetpack-io/devbox
                    </div>
                  </div>
                </template>
              </v-textarea>

              <v-btn
                :disabled="savingManual"
                :loading="savingManual"
                :ripple="false"
                class="btn-bg-gradient"
                color="primary"
                elevation="0"
                rounded
                @click="submitManual"
              >
                {{ $t('Add') }}
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
        -->

        <!-- From composer.json -->
        <v-row class="mt-10">
          <v-col cols="12" md="7">
            <template v-if="serverFeedback.deps || project.dependenciesSource || licensesAuto.length">
              <template v-if="loadingAuto">
                <h4 class="mb-4">
                  <v-progress-circular
                    class="mr-2"
                    color="grey"
                    indeterminate
                    size="24"
                    width="3"
                  />
                  {{ $t('Discovering dependencies') }}
                </h4>
                <p class="text--secondary">
                  Процесс поиска лицензий зависимостей может занять длительное время.
                </p>
                <p class="text--secondary">
                  Вы можете продолжить использовать функционал личного кабинета и вернуться
                  на данную страницу позже для просмотра результатов поиска.
                </p>

                <v-progress-linear
                  v-if="discoveryProgress !== -1"
                  :value="discoveryProgress"
                  buffer-value="0"
                  class="mt-10"
                  stream
                />
              </template>
              <template v-else-if="serverFeedback.deps || licensesAuto.length === 0">
                <h4 class="mb-3">
                  {{ $t('Failed to get dependencies') }}
                </h4>
                <p v-if="serverFeedback.depsMessage">
                  {{ serverFeedback.depsMessage }}
                </p>
                <p v-else>
                  При обработке файла пакетного менеджера не удалось получить список зависимостей.
                </p>
              </template>
              <template v-else>
                <h4 class="mb-3">
                  {{ $t('Automatically discovered dependencies') }}
                  <v-chip>
                    {{ licensesAuto.length }}
                  </v-chip>
                </h4>

                <v-tabs v-model="licenseGroupTab">
                  <v-tab
                    v-for="(group) in licensesAutoGrouped"
                    :key="`lic-tab-${group.id}`"
                    :href="`#lic-group-${group.id}`"
                    :ripple="false"
                  >
                    {{ group.title }}
                    <v-chip
                      v-if="group.licenses.length"
                      class="ml-1"
                      :color="group.chipColor"
                      outlined
                      x-small
                    >
                      {{ group.licenses.length }}
                    </v-chip>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="licenseGroupTab">
                  <v-tab-item
                    v-for="(group) in licensesAutoGrouped"
                    :key="`lic-tab-item-${group.id}`"
                    :value="`lic-group-${group.id}`"
                  >
                    <v-list>
                      <ProjectLibLicenseAuto
                        v-for="lib in group.licenses"
                        :key="`lib-${lib.id}-${lib.vendor}`"
                        :lib="lib"
                      />
                    </v-list>
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </template>
          </v-col>

          <v-col cols="12" md="5">
            <h4 class="mb-3">
              {{ $t('Automatic dependencies discovery') }}
            </h4>
            <v-form ref="formAuto" enctype="multipart/form-data" @submit.prevent>
              <v-row>
                <v-col cols="auto" class="pt-5">
                  {{ $t('Project language') }}
                </v-col>
                <v-col>
                  <v-select
                    v-model="langVerSelected"
                    :items="langSelectItems"
                    dense
                    outlined
                    @change="clearErrors"
                  />
                </v-col>
              </v-row>

              <v-card
                :class="{dragover, disabled: !langVerSelected.language }"
                :ripple="false"
                class="pa-3 mb-3 dropzone"
                min-height="100"
                outlined
                @click="openChooseFile"
                @dragleave="dragover = false"
                @dragover.prevent="dragover = true"
                @drop.stop.prevent="onDrop"
              >
                <template v-if="langVerSelected.language">
                  <div v-if="file" class="d-flex flex-column align-center">
                    <v-icon large v-text="icons.file" />
                    <div class="mt-2">{{ file.name }}</div>
                  </div>
                  <div v-else class="text-center">
                    {{ $t('Drop file here or click to select a file') }}
                  </div>
                  <div v-if="serverFeedback.file" class="text-caption error--text">
                    {{ serverFeedback.file }}
                  </div>

                  <div v-if="file" class="clear">
                    <v-btn
                      :ripple="false"
                      elevation="0"
                      rounded
                      small
                      @click="clearFile"
                    >
                      {{ $t('Clear') }}
                    </v-btn>
                  </div>
                </template>
                <div v-else class="text--secondary">
                  {{ $t('First choose language of the project') }}
                </div>
              </v-card>
              <input
                ref="fileInput"
                class="d-none"
                type="file"
                @change="onDrop"
              >

              <div v-if="serverFeedbackOther" class="text-caption error--text">
                {{ serverFeedbackOther }}
              </div>
              <v-btn
                :disabled="cantUpload"
                :loading="savingAuto"
                :ripple="false"
                class="btn-bg-gradient"
                color="primary"
                elevation="0"
                rounded
                @click="submitAuto"
              >
                {{ $t('Upload') }}
              </v-btn>
            </v-form>

            <template v-if="showDependenciesSource">
              <h4 class="mt-16 mb-3">
                {{ $t('Content of uploaded dependencies file') }}
              </h4>
              <pre>{{ project.dependenciesSource }}</pre>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {mdiFileOutline} from '@mdi/js'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import ProjectLibLicenseAuto from '@/components/ProjectLibLicenseAuto.vue'
import ProjectLibLicenseManual from '@/components/ProjectLibLicenseManual.vue'
import error401handler from '@/mixins/error-401-handler'

// сколько делать попыток загрузки зависимостей после аплоада composer.json
const maxRetries = 5
let retry = 0
// время между попытками, мс
const retryTimeout = 3000

export default {
  name: 'ProjectLicenses',
  components: {
    AppLinkBackToDeposits,
    ProjectLibLicenseAuto,
    ProjectLibLicenseManual,
  },
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
  },
  data () {
    return {
      langVerSelected: {
        language: '',
        version: '',
      },
      libs: '',
      rules: {
        libs: [v => !!v || this.$t('At least one dependency is required')],
      },
      file: null,
      serverFeedback: {
        file: '',
        libs: '',
        deps: false,
        depsMessage: '',
      },
      reloading: false,
      loading: false,
      savingAuto: false,
      savingManual: false,
      loadingAuto: false,
      loadingManual: false,
      discoveryProgress: null,
      dragover: false,
      icons: {
        file: mdiFileOutline,
      },
      licenseGroupTab: 'forbidden',
      timer: null,
      licenseGroups: [
        {
          id: 'forbidden',
          title: 'Сомнительные',
          chipColor: 'red',
          licenseKeys: [],
        },
        {
          id: 'undefined',
          title: 'Неопределенные',
          chipColor: '',
          licenseKeys: [],
        },
        {
          id: 'allowed',
          title: 'Разрешенные',
          chipColor: 'green',
          licenseKeys: [],
        },
      ],
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      projectsLoaded: state => state.projectsBusiness.projectsLoaded,
      languages: state => state.config.lictrack.languages,
      licenseGroupByKeys: state => state.config.lictrack.groupByKeys,
    }),
    ...mapGetters({
      getProjectById: 'projectsBusiness/getProjectById',
    }),
    project () {
      return this.getProjectById(this.projectId)
    },
    licensesAuto () {
      return this.project?.licensesAuto || []
    },
    licensesManual () {
      return this.project?.licensesManual || []
    },
    langSelectItems () {
      let items = []
      for (let [language, {title, versions}] of Object.entries(this.languages)) {
        if (versions.length) {
          for (let version of versions) {
            items.push({
              text: `${title} ${version}`,
              value: {language, version},
            })
          }
        } else {
          items.push({
            text: title,
            value: {language, version: ' '},
          })
        }
      }
      return items
    },
    knownLicenseKeys () {
      return this.licenseGroups.reduce((keys, group) => {
        keys.push(...group.licenseKeys.map(key => key.toLowerCase()))
        return keys
      }, [])
    },
    licensesAutoGrouped () {
      let groups = []
      for (let group of this.licenseGroups) {
        let licenses = []
        if (group.licenseKeys.length) {
          licenses = this.licensesAuto.filter(lic => {
            const key = lic.licenseKey || lic.licenseName
            return typeof key === 'string' &&
              group.licenseKeys.includes(key.toLowerCase())
          })
        } else {
          // не определённые — все лицензии, что не попали в известные списки
          licenses = this.licensesAuto.filter(lic => {
            const key = lic.licenseKey || lic.licenseName
            return typeof key !== 'string' ||
              !this.knownLicenseKeys.includes(key.toLowerCase())
          })
        }
        groups.push({...group, licenses})
      }
      return groups
    },
    acceptFiles () {
      if (this.langVerSelected.language) {
        return this.languages[this.langVerSelected.language].acceptFiles
      }
      return []
    },
    serverFeedbackOther () {
      let feedback = []
      for (let [key, value] of Object.entries(this.serverFeedback)) {
        if (!['file', 'deps', 'depsMessage'].includes(key)) {
          if (Array.isArray(value)) {
            feedback.push(...value)
          } else {
            feedback.push(value)
          }
        }
      }
      return feedback.join(' ')
    },
    showDependenciesSource () {
      return this.project.dependenciesSource && ['success', 'new'].includes(this.project.dependenciesStatus)
    },
    cantUpload () {
      return this.savingAuto || !this.langVerSelected.language || !this.file
    },
    shouldReloadAutoDeps () {
      return this.project.dependenciesStatus === 'new' && this.licensesAuto.length === 0
    },
  },
  async created () {
    // if (this.project.licenses) {
    //   this.reloading = true
    // } else {
    //   this.loading = true
    // }

    this.loading = true
    try {
      await this.getManualDeps(this.projectId)
      let {result, data} = await this.getAutoDeps(this.projectId)
      if  (!result) {
        this.serverFeedback.deps = true
        this.serverFeedback.depsMessage = data
      }
    } catch (e) {
      if (e.response?.status === 404) {
        this.serverFeedback.deps = true
      } else {
        this.handleError(e)
      }
    } finally {
      // this.reloading = false
      this.loading = false
    }

    for (let [id, licKeys] of Object.entries(this.licenseGroupByKeys)) {
      this.licenseGroups.find(lg => lg.id === id)?.licenseKeys.push(...licKeys.map(key => key.toLowerCase()))
    }

    if (this.shouldReloadAutoDeps) {
      this.loadAutoDeps()
    }
  },
  methods: {
    ...mapMutations({
      setError: 'error/set',
      showNotify: 'notify/show',
    }),
    ...mapActions({
      addLibs: 'projectsBusiness/prAddLibs',
      getManualDeps: 'projectsBusiness/prGetLicenses',
      stopTrackingLicense: 'projectsBusiness/prStopTrackingLicense',
      upload: 'projectsBusiness/prStoreDependenciesFile',
      getAutoDeps: 'projectsBusiness/prListDependencies',
    }),
    clearErrors () {
      for (let key of Object.keys(this.serverFeedback)) {
        this.serverFeedback[key] = null
      }
    },
    async stopTracking (lib) {
      try {
        await this.stopTrackingLicense({id: this.projectId, libName: lib.name})
        this.showNotify({
          text: this.$t('Stopped tracking dependency license'),
          color: 'success',
        })
      } catch (e) {
        this.handleError(e)
      }
    },
    async submitManual () {
      if (!this.$refs.formManual.validate()) {
        return false
      }

      this.savingManual = true
      this.clearErrors()
      try {
        await this.addLibs({id: this.projectId, libs: this.libs})
        this.showNotify({
          text: this.$t('Dependencies added'),
          color: 'success',
        })
        this.$refs.formManual.$el.reset()
      } catch (e) {
        if ([400, 404, 422].includes(e.response?.status)) {
          this.serverFeedback.libs = e.response.data.message
        } else {
          this.handleError(e)
        }
      } finally {
        this.savingManual = false
      }
    },
    openChooseFile () {
      if (this.langVerSelected.language) {
        this.$refs.fileInput.click()
      }
    },
    onDrop (e) {
      this.serverFeedback.file = ''
      this.dragover = false
      let files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      if (this.langVerSelected.language) {
        this.file = files[0]
      }
    },
    clearFile (e) {
      e.preventDefault()
      e.stopPropagation()
      this.file = null
      this.$refs.fileInput.value = null
      this.serverFeedback.file = ''
    },
    async submitAuto () {
      this.clearErrors()

      if (!this.file) {
        this.serverFeedback.file = this.$t('File is required')
        return false
      }
      const fileNameSplitted = this.file.name?.split('.')
      const fileExt = fileNameSplitted[fileNameSplitted.length - 1]
      // В acceptFiles могут лежать как расширения файлов, так и полные названия
      if (fileExt && !this.acceptFiles.includes(fileExt) && !this.acceptFiles.includes(this.file.name)) {
        this.serverFeedback.file = this.$t('Accept only files from list', {acceptFiles: this.acceptFiles.join(', ')})
        return false
      }

      this.loadingAuto = false
      this.savingAuto = true
      try {
        let {data, result} = await this.upload({
          id: this.projectId,
          form: {
            ...this.langVerSelected,
            dependencies: this.file,
          },
        })
        if (result) {
          this.showNotify({text: data, color: 'success'})
          await this.loadAutoDeps()
        } else {
          this.showNotify({text: data, color: 'error'})
        }
        this.$refs.formAuto?.$el.reset()
        this.file = null
      } catch (e) {
        if (e.response?.status === 422) {
          let {dependencies, ...message} = e.response.data?.message || {}
          if (dependencies?.[0]?.startsWith('Поле dependencies должно быть файлом одного из следующих типов')) {
            this.serverFeedback.file = this.$t('File has to be in json format')
          } else {
            this.serverFeedback.file = dependencies?.join(' ')
          }
          this.serverFeedback = {...this.serverFeedback, ...message}
        } else {
          this.handleError(e)
        }
      } finally {
        this.savingAuto = false
      }
    },
    async loadAutoDeps () {
      this.loadingAuto = true
      retry++
      try {
        let {result, data, complete = null} = await this.getAutoDeps(this.projectId)
        if (result) {
          this.discoveryProgress = complete

          if (this.shouldReloadAutoDeps) {
            this.timer = setTimeout(this.loadAutoDeps, retryTimeout)
          } else {
            this.loadingAuto = false
            this.discoveryProgress = null
          }
        } else {
          this.loadingAuto = false
          this.serverFeedback.deps = true
          this.serverFeedback.depsMessage = data
          this.discoveryProgress = null
        }
      } catch (e) {
        if (e.response?.status === 404) {
          this.serverFeedback.deps = true
        } else {
          this.handleError(e)
        }
      } finally {
        // this.reloading = false
        this.loading = false
      }
    },
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
}
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  border: thin solid var(--v-outline-base);
  border-radius: $border-radius-small;
  padding: $spacer * 2 $spacer * 4;
  font-size: 0.625rem;
}

.drop-hint {
  display: none;
  color: var(--v-surface-base);
  font-size: 3rem;
}

.dropzone {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .change {
    display: none;
    background-color: #00000073;
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding-bottom: .5rem;
  }

  &:hover .change {
    display: block;
  }

  &.dragover {
    border-color: red;
    border-style: dashed;
    background-color: #00000010;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  & .clear {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 2;
  }
}
</style>
