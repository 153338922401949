<template>
  <div>
    <template v-if="profileLoaded && project">
      <v-container>
        <app-link-back-to-deposits
          :text="$t('Back to projects')"
          :to="{name: 'projects'}"
        />
        <h1 class="mb-4 text-h1">
          <span class="text-gradient">{{ project.name }}</span>
        </h1>

        <v-tabs>
          <v-tab :ripple="false" :to="{name: 'project-teammates', params: {projectId}}">
            <v-icon v-text="icons.teammates" class="me-2" />
            {{ $t('Teammates') }}
          </v-tab>
          <v-tab :ripple="false" :to="{name: 'project-documents', params: {projectId}}">
            <v-icon v-text="icons.documents" class="me-2" />
            {{ $t('Documents') }}
          </v-tab>
          <v-tab :ripple="false" :to="{name: 'project-licenses', params: {projectId}}">
            <v-icon v-text="icons.licenses" class="me-2" />
            {{ $t('Dependencies licenses') }}
          </v-tab>
        </v-tabs>
      </v-container>

      <router-view />
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import error401handler from '@/mixins/error-401-handler'
import {mdiAccountMultiple, mdiFileDocumentMultipleOutline, mdiGraphOutline} from '@mdi/js'

export default {
  name: 'Project',
  components: {AppLinkBackToDeposits},
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
  },
  data () {
    return {
      loading: false,
      reloading: false,
      icons: {
        teammates: mdiAccountMultiple,
        documents: mdiFileDocumentMultipleOutline,
        licenses: mdiGraphOutline,
      },
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      profile: state => state.profile,
      projectsLoaded: state => state.projectsBusiness.projectsLoaded,
    }),
    ...mapGetters({
      getProjectById: 'projectsBusiness/getProjectById',
    }),
    project () {
      return this.getProjectById(this.projectId)
    },
  },
  async created () {
    if (!this.project) {
      this.loading = true
      try {
        await this.getProject(this.projectId)
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    profileLoaded (val) {
      if (this.profile.role !== 'manager') {
        return this.$router.replace({name: 'projects'})
      }
    }
  },
  methods: {
    ...mapActions({
      getProject: 'projectsBusiness/prGet',
    }),
  },
}
</script>
