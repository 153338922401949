<template>
  <v-container class="deposit-container pb-0 pb-sm-3">
    <v-row>
      <v-col>
        <v-card
          :ripple="false"
          class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
          flat
          outlined
        >
          <v-overlay :value="loading" opacity="0.1" absolute>
            <v-progress-circular color="primary" indeterminate />
          </v-overlay>

          <v-menu offset-y>
            <template #activator="{on, attrs}">
              <v-btn
                :ripple="false"
                class="btn-bg-gradient"
                color="primary"
                elevation="0"
                rounded
                v-on="on"
              >
                {{ $t('Create document') }}
                <v-icon right v-text="icons.caret" />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(doc, type) in documentTypes"
                :key="type"
                :ripple="false"
                :to="{name: 'project-create-document', params: {type}}"
                class="text-decoration-none"
              >
                {{ doc.name }}
              </v-list-item>
            </v-list>
          </v-menu>

          <v-list>
            <v-list-item
              v-for="doc in docs"
              :key="doc.id"
              :ripple="false"
              link
              @click="downloadPdf(doc)"
            >
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template #activator="{on}">
                    <v-icon v-text="icons.document" v-on="on" />
                  </template>
                  <template>
                    {{ $t('Download document in PDF') }}
                  </template>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ documentTypes[doc.type].name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ dateFormat(doc.data.document.date, 'dd.mm.yyyy') }},
                  версия {{ doc.data.project.version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Период с
                  {{ dateFormat(doc.data.document.period.startDate, 'dd.mm.yyyy') }}
                  по
                  {{ dateFormat(doc.data.document.period.endDate, 'dd.mm.yyyy') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import {mdiMenuDown, mdiFileDocumentOutline} from '@mdi/js'
import {saveAs} from 'file-saver'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import error401handler from '@/mixins/error-401-handler'
import {dateFormat} from '@/util/dates'

export default {
  name: 'ProjectDocuments',
  components: {AppLinkBackToDeposits},
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
  },
  data () {
    return {
      project: {},
      serverFeedback: {
        name: null
      },
      valid: true,
      loading: false,
      saving: false,
      removing: false,
      documents: [],
      icons: {
        caret: mdiMenuDown,
        document: mdiFileDocumentOutline,
      },
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      allTeammates: state => state.profile.company?.teammates,
      documentTypes: state => state.config.app.docs.types,
    }),
    ...mapGetters({
      getProjectById: 'projectsBusiness/getProjectById',
    }),
    projectSource () {
      return this.getProjectById(this.projectId)
    },
    docs () {
      return this.projectSource?.documents?.data || []
    },
  },
  async created () {
    this.loading = true
    try {
      await this.loadDocs(this.projectId)
    } catch (e) {
      this.handleError(e)
    } finally {
      this.loading = false
    }

    if (this.projectSource) {
      this.project = cloneDeep(this.projectSource)
    } else {
      this.setError({
        mode: 'global',
        title: this.$t('Project not found'),
      })
    }
  },
  methods: {
    dateFormat,
    ...mapMutations({
      setError: 'error/set',
    }),
    ...mapActions({
      loadProjects: 'projectsBusiness/prLoad',
      loadDocs: 'projectsBusiness/prLoadDocuments',
      downloadDocumentPdf: 'projectsBusiness/prDownloadDocumentPdf',
    }),
    async downloadPdf (doc) {
      try {
        let {data} = await this.downloadDocumentPdf(doc.id)
        saveAs(data, `${doc.type}-${doc.data.document.date}.pdf`)
      } catch (e) {
        this.handleError(e)
      }
    },
  },
}
</script>
