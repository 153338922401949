<template>
  <v-container>
    <!-- Projects breadcrumbs -->
    <v-row align-v="center" no-gutters>
      <v-col class="text--disabled" cols="auto">
        <v-breadcrumbs :items="breadcrumbs" class="px-0 pb-1">
          <template v-slot:divider>
            <v-icon small v-text="icons.next" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <!-- Nav and filters -->
    <v-row class="my-0">
      <v-col
        v-for="section in sections"
        :key="`section-${section.id}`"
        cols="auto"
      >
        <h2 class="text-no-wrap text-h4">
          <router-link
            :to="sectionRoute(section.id)"
            v-text="$t(section.title)"
          />
          <v-btn
            :color="sectionId === section.id ? 'primary' : 'surface'"
            :ripple="false"
            :to="sectionRoute(section.id)"
            :style="{opacity: sectionId === section.id ? 1 : 0}"
            class="ml-2"
            elevation="0"
            fab
            small
          >
            {{ sectionDepositsCount(section.id) }}
          </v-btn>
        </h2>
      </v-col>

      <v-spacer />

      <v-col cols="auto" class="d-lg-none text-right">
        <v-btn
          :ripple="false"
          :color="showFilters ? 'primary' : 'grey'"
          class="px-0"
          icon
          @click="showFilters = !showFilters"
        >
          <v-icon v-text="icons.filter" />
        </v-btn>
      </v-col>
      <!-- Deposits filter -->
      <v-col
        :class="[showFilters ? 'd-block' : 'd-none d-lg-block']"
        class="align-center justify-end mb-3"
        cols="12"
        lg="auto"
      >
        <v-row justify="end">
          <v-col cols="12" sm="auto" class="d-flex align-center justify-end">
            <div class="mr-2 grey--text">
              {{ $t('Deposits per page') }}
            </div>
            <div class="selector--per-page">
              <v-select
                :items="pagination.perPageVariants"
                :menu-props="{ripple: false, offsetY: true}"
                :value="pagination.perPage"
                hide-details
                solo
                @change="updatePerPage"
              >
                <template v-slot:item="{attrs, item, on}">
                  <v-list-item v-bind="attrs" :ripple="false" v-on="on">
                    <span>{{ item }}</span>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="auto" class="d-flex justify-end">
            <div class="selector--deposit-type">
              <v-select
                :items="depositTypeFilter"
                :menu-props="{ripple: false, offsetY: true}"
                :value="currentFilter"
                hide-details
                return-object
                solo
                @change="gotoFiltered"
              >
                <template v-slot:selection="{item}">
                  <v-icon v-if="item.value" class="mr-4" v-text="icons[item.value]" />
                  <span :class="{'pl-10': !item.value}">{{ item.text }}</span>
                </template>
                <template v-slot:item="{attrs, item, on}">
                  <v-list-item v-bind="attrs" :ripple="false" v-on="on">
                    <v-icon v-if="item.value" class="mr-4" v-text="icons[item.value]" />
                    <span :class="{'pl-10': !item.value}">{{ item.text }}</span>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="auto">
            <div>
              <v-text-field
                v-model="search"
                :placeholder="$t('Filter deposits')"
                :append-icon="icons.search"
                clearable
                hide-details
                solo
                @keyup.enter="doSearch"
                @click:append="doSearch"
                @click:clear="clearSearch"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div>
      <v-overlay :value="loading" absolute color="var(--v-surface-base)">
        <v-progress-circular color="primary" indeterminate />
      </v-overlay>

      <!-- deposits list -->
      <deposits-list :deposits="deposits" :loading="loading" />

      <!-- pagination -->
      <div v-if="pagination.totalPages > 1">
        <v-row align="center" class="mt-3">
          <v-col class="text--secondary text-body-2">
            {{ from }}–{{ to }}
            {{ $t('OfDepositsTotalCounts', {count: pagination.total}) }}
          </v-col>
          <v-col cols="auto">
            <v-pagination
              :length="pagination.totalPages"
              :next-icon="icons.next"
              :prev-icon="icons.prev"
              :total-visible="12"
              :value="pagination.currentPage"
              data-cy="pagination"
              @input="gotoPage"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {
  mdiArchiveOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCogOutline,
  mdiFilter,
  mdiMagnify,
  mdiCodeTags,
} from '@mdi/js'
import {mdiFigmaOutline, mdiFrame} from '@/assets/mdi-custom-icons'
import DepositsList from '@/components/DepositsList'
import error401handler from '@/mixins/error-401-handler'
import {sections} from '@/store/const-deposit-statuses'

export default {
  name: 'Deposits',
  components: {DepositsList},
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
    sectionId: {type: String, required: true}
  },
  data () {
    return {
      sections,
      search: '',
      loading: false,
      showFilters: false,
      icons: {
        algorithm: mdiCogOutline,
        design: mdiFigmaOutline,
        filter: mdiFilter,
        implementation: mdiCodeTags,
        next: mdiChevronRight,
        prev: mdiChevronLeft,
        project: mdiArchiveOutline,
        screens: mdiFrame,
        search: mdiMagnify,
      }
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
      profileLoaded: state => state.loaders.profile.status,
      // TODO refactor — now copy-pasted Prv vs Biz with adapters
      projectsLoadedPrv: state => state.projects?.projectsLoaded,
      projectsPrv: state => state.projects?.projects,
      depositsPrv: state => state.deposits.deposits,
      paginationPrv: state => state.deposits.pagination,
      filtersPrv: state => state.deposits.filters,
      projectsLoadedBiz: state => state.projectsBusiness?.projectsLoaded,
      projectsBiz: state => state.projectsBusiness?.projects,
      depositsBiz: state => state.depositsBusiness.deposits,
      paginationBiz: state => state.depositsBusiness.pagination,
      filtersBiz: state => state.depositsBusiness.filters,
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    projects () {
      return this.isBusinessAccount ? this.projectsBiz : this.projectsPrv
    },
    projectsLoaded () {
      return this.isBusinessAccount ? this.projectsLoadedBiz : this.projectsLoadedPrv
    },
    deposits () {
      return this.isBusinessAccount ? this.depositsBiz : this.depositsPrv
    },
    pagination () {
      return this.isBusinessAccount ? this.paginationBiz : this.paginationPrv
    },
    filters () {
      return this.isBusinessAccount ? this.filtersBiz : this.filtersPrv
    },
    ...mapGetters({
      depositTypes: 'getDepositTypes',
      projectNameByIdPrv: 'projects/getNameById',
      projectNameByIdBiz: 'projectsBusiness/getNameById',
      getProjectPrvById: 'projects/getProjectById',
      getProjectBizById: 'projectsBusiness/getProjectById',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    projectNameById () {
      return this.isBusinessAccount ? this.projectNameByIdBiz : this.projectNameByIdPrv
    },
    getProjectById () {
      return this.isBusinessAccount ? this.getProjectBizById : this.getProjectPrvById
    },
    depositTypeFilter () {
      return [
        {text: this.$t('All deposit types'), value: null},
        ...this.depositTypes
      ]
    },
    from () {
      return (this.pagination.perPage * (this.pagination.currentPage - 1)) + 1
    },
    to () {
      return this.pagination.total < this.pagination.perPage * this.pagination.currentPage
        ? this.pagination.total
        : this.pagination.perPage * this.pagination.currentPage
    },
    breadcrumbs () {
      let bc = [{
        text: this.$t('All projects'),
        to: {
          name: 'deposits',
          params: {
            projectId: 'all',
            sectionId: this.sectionId
          }
        }
      }]
      if (this.projectId !== 'all') {
        bc.push({
          text: this.projectNameById(this.projectId)
        })
      }

      return bc
    },
    currentFilter () {
      return this.depositTypeFilter.find(filter => {
        return filter.value === this.filters.type
      })
    },
  },
  watch: {
    '$route' () {
      this.initialLoad()
    },
    profileLoaded (val) {
      if (val) this.initialLoad()
    }
  },
  created () {
    if (!sections.map(s => s.id).includes(this.$route.params.sectionId)) {
      this.setError({
        mode: 'global',
        title: this.$t('Page not found'),
        message: this.$t('ErrorWrongDepositSection', {
          sectionId: this.$route.params.sectionId
        }),
      })
    } else {
      this.search = this.$route.query.search || ''
      this.loading = true
      if (this.profileLoaded) this.initialLoad()
    }
  },
  methods: {
    async initialLoad () {
      this.loading = true
      try {
        await this.loadDeposits()
      } catch (e) {
        console.log({e})
        if (e.response?.status === 404) {
          this.setError({
            mode: 'modal',
            title: this.$t('Page not found'),
            message: this.$t('ErrorDepositListPageNotFound'),
          })
        } else {
          this.handleError(e)
        }
      } finally {
        this.loading = false
      }

      this.loading = true
      try {
        await this.loadProjects()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    ...mapMutations({
      setError: 'error/set',
      setFilterPrv: 'deposits/setFilterType',
      setFilterBiz: 'depositsBusiness/setFilterType',
      setPerPagePrv: 'deposits/setPerPage',
      setPerPageBiz: 'depositsBusiness/setPerPage',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    setFilter () {
      return (this.isBusinessAccount ? this.setFilterBiz : this.setFilterPrv)(...arguments)
    },
    setPerPage () {
      return (this.isBusinessAccount ? this.setPerPageBiz : this.setPerPagePrv)(...arguments)
    },
    ...mapActions({
      loadProjectsPrv: 'projects/prLoad',
      loadProjectsBiz: 'projectsBusiness/prLoad',
      dpLoadPrv: 'deposits/dpLoad',
      dpLoadBiz: 'depositsBusiness/dpLoad',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    loadProjects () {
      return (this.isBusinessAccount ? this.loadProjectsBiz : this.loadProjectsPrv)(...arguments)
    },
    dpLoad () {
      return (this.isBusinessAccount ? this.dpLoadBiz : this.dpLoadPrv)(...arguments)
    },
    async loadDeposits () {
      await this.dpLoad({
        page: this.$route.query.page,
        projectId: this.projectId,
        search: this.search,
        sectionId: this.sectionId,
        type: this.filters.type,
      })
    },
    async reloadDeposits () {
      let search = {}
      if (this.search) search = {search: this.search}

      if (this.$route.query.page) {
        await this.$router.replace({query: {...search}})
      } else {
        this.loading = true
        try {
          await this.dpLoad({
            ...search,
            projectId: this.projectId,
            sectionId: this.sectionId,
            type: this.filters.type,
          })
        } catch (e) {
          console.log({e})
          if (e.response?.status === 404) {
            this.setError({
              mode: 'modal',
              title: this.$t('Page not found'),
              message: this.$t('ErrorDepositListPageNotFound'),
            })
          } else {
            this.handleError(e)
          }
        } finally {
          this.loading = false
        }
      }
    },
    gotoPage (page) {
      if (page && page !== this.pagination.currentPage) {
        let query = {}
        if (page > 1) query.page = page
        if (this.search) query.search = this.search
        this.$router.push({query})
      }
    },
    async gotoFiltered (depositType) {
      this.setFilter(depositType.value)
      await this.reloadDeposits()
    },
    async updatePerPage (value) {
      this.setPerPage(value)
      await this.reloadDeposits()
    },
    async doSearch () {
      await this.$router.push({query: {search: this.search}})
    },
    async clearSearch () {
      this.search = ''
      await this.$router.push({query: {}})
    },
    sectionRoute (sectionId) {
      return {
        name: 'deposits',
        params: {
          projectId: this.projectId,
          sectionId
        }
      }
    },
    sectionDepositsCount (sectionId) {
      return this.loading
        ? ''
        : this.pagination?.depositsFilteredCount?.[sectionId]
    },
  }
}
</script>

<style lang="scss" scoped>
h2 {
  a {
    color: var(--v-text-base);
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.router-link-active {
      border-bottom: $active-nav-border;
    }
  }
}

.selector--per-page {
  width: 11ch;
}

.selector--deposit-type {
  width: 28ch;
}
</style>
