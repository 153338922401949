<template>
  <v-list-item :disabled="lib.status === 'stopped'">
    <v-list-item-content>
      <div>
        {{ lib.name }}
      </div>

      <template v-if="lib.status === 'tracking'">
        <div v-if="hasCurrentLicense" class="text-caption">
          <a :href="currentLicense.uri" target="_blank" class="text-decoration-underline">{{ currentLicense.name }}</a>,
          {{ currentDate }}
        </div>
        <div v-else>
          <div class="text-caption text--secondary  ">
            {{ $t('No license') }}
          </div>
        </div>

        <div v-if="hasLastLicense" class="text-caption error--text">
          {{ $t('Previous license') }}:
          <a :href="lastLicense.uri" target="_blank" class="error--text text-decoration-underline">{{ lastLicense.name }}</a>,
          {{ $t('until') }}
          {{ lastDate }}
        </div>
      </template>
    </v-list-item-content>

    <v-list-item-icon v-if="lib.status === 'tracking'">
      <v-tooltip left>
        <template v-slot:activator="{on, attr}">
          <v-btn
            :ripple="false"
            color="transparent"
            elevation="0"
            fab
            small
            @click="$emit('stop', lib)"
            v-on="on"
          >
            <v-icon v-text="icons.stop" />
          </v-btn>
        </template>
        <span>{{ $t('Stop license tracking') }}</span>
      </v-tooltip>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import {mdiClose} from '@mdi/js'
import {dateFormat} from '@/util/dates'

export default {
  name: 'ProjectLibLicenseManual',
  props: {
    lib: {type: Object, required: true},
  },
  data () {
    return {
      icons: {
        stop: mdiClose,
      },
    }
  },
  computed: {
    hasCurrentLicense () {
      return !!this.lib.history?.[0]
    },
    currentLicense () {
      return this.lib.history[0]
    },
    currentDate () {
      return dateFormat(this.currentLicense.updated_at)
    },
    hasLastLicense () {
      return !!this.lib.history?.[1]
    },
    lastLicense () {
      return this.lib.history[1]
    },
    lastDate () {
      return dateFormat(this.lastLicense.updated_at)
    },
  },
}
</script>
