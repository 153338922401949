<template>
  <v-list-item>
    <v-list-item-content>
      <div>
        {{ lib.vendor }}
        <span class="text-caption text--secondary">{{ lib.version }}</span>
        <a v-if="showUrl" :href="lib.url" target="_blank" class="github-link">
          <v-icon class="me-2 github-link" v-text="icons.github" />
        </a>
      </div>

      <div v-if="hasCurrentLicense" class="text-caption d-inline-flex">
        <a
          v-if="lib.licenseUri"
          :href="lib.licenseUri"
          class="text-decoration-underline"
          target="_blank"
        >{{ licenseName }}</a>
        <span v-else>{{ licenseName }}</span>
        ,
        {{ licenseDate }}
      </div>
      <div v-else>
        <div class="text-caption text--secondary">
          {{ $t('No license') }}
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mdiClose, mdiGithub} from '@mdi/js'
import {dateFormat} from '@/util/dates'
import { isUrl } from '@/util/util'

export default {
  name: 'ProjectLibLicenseAuto',
  props: {
    lib: {type: Object, required: true},
  },
  data () {
    return {
      icons: {
        github: mdiGithub,
        stop: mdiClose,
      },
    }
  },
  computed: {
    hasCurrentLicense () {
      return !!this.lib.licenseKey
    },
    licenseName () {
      return this.lib.licenseName?.replaceAll('\\\"', '"')?.replace(/^"(.+)"$/m, '$1')
    },
    licenseDate () {
      if (this.lib.updated_at) {
        return dateFormat(this.lib.updated_at)
      }
      return this.lib.created_at ? dateFormat(this.lib.created_at) : '—'
    },
    showUrl () {
      return isUrl(this.lib.url)
    }
  },
}
</script>

<style scoped>
.github-link {
  padding-left: 4px;
}

.github-link:hover {
  opacity: 0.8;
}
</style>
