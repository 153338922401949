<template>
  <div>
    <v-row v-if="allowManageProjects" align="center">
      <v-col cols="auto">
        <v-btn
          :ripple="false"
          :to="{name: 'project-add'}"
          class="mt-1 btn-light-border-gradient"
          color="primary"
          outlined
          rounded
        >
          {{ $t('Create project') }}
        </v-btn>
      </v-col>
      <transition v-if="reloading" name="fade">
        <v-col>
          <v-progress-circular indeterminate />
        </v-col>
      </transition>
    </v-row>

    <div v-if="loading" class="py-16 text-center">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-row v-else>
      <v-col
        v-for="project in projects"
        :key="`pr-${project.id}`"
        cols
        lg="3"
        md="4"
        sm="6"
        xl="2"
      >
        <v-card
          class="d-flex flex-column"
          flat
          height="100%"
          outlined
        >
          <v-card-title class="flex-nowrap">
            <div class="project-title">
              {{ project.name }}
            </div>
            <v-spacer />
            <project-dialog-add-edit
              v-if="allowManageProjects"
              :key="`prj-dialog-${project.id}`"
              :project="project"
            />
          </v-card-title>

          <v-list dense>
            <template v-if="allowManageProjects">
              <v-subheader class="text-uppercase">
                {{ $t('Project parameters') }}
              </v-subheader>

              <v-list-item
                :ripple="false"
                :to="{name: 'project-teammates', params: {projectId: project.id}}"
              >
                <v-list-item-icon>
                  <v-icon v-text="icons.teammates" />
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Teammates') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :ripple="false"
                :to="{name: 'project-documents', params: {projectId: project.id}}"
              >
                <v-list-item-icon>
                  <v-icon v-text="icons.documents" />
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Documents') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :ripple="false"
                :to="{name: 'project-licenses', params: {projectId: project.id}}"
                class="mb-4"
              >
                <v-list-item-icon>
                  <v-icon v-text="icons.licenses" />
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Dependencies licenses') }}
                </v-list-item-title>
              </v-list-item>
            </template>


            <v-subheader class="text-uppercase">
              {{ $t('Deposits') }}
            </v-subheader>

            <v-list-item
              :disabled="!project.depositsCount.registered"
              :ripple="false"
              :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
            >
              <v-list-item-title class="d-flex align-center">
                <div>{{ $t('RegisteredAdjPlural') }}</div>
                <v-spacer />
                <v-chip
                  v-if="project.depositsCount.registered"
                  class="text-decoration-none"
                  color="success"
                  elevation="0"
                  outlined
                  small
                >
                  {{ project.depositsCount.registered }}
                </v-chip>
                <div v-else class="text--secondary mr-2">—</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              :disabled="!project.depositsCount.inProgress"
              :ripple="false"
              :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
            >
              <v-list-item-title class="d-flex align-center">
                <div>{{ $t('In progress') }}</div>
                <v-spacer />
                <v-chip
                  v-if="project.depositsCount.inProgress"
                  class="text-decoration-none"
                  color="primary"
                  elevation="0"
                  outlined
                  small
                >
                  {{ project.depositsCount.inProgress }}
                </v-chip>
                <div v-else class="text--secondary mr-2">—</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              :disabled="!project.depositsCount.draft"
              :ripple="false"
              :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'drafts'}}"
            >
              <v-list-item-title class="d-flex align-center">
                <div>{{ $t('Drafts') }}</div>
                <v-spacer />
                <v-chip
                  v-if="project.depositsCount.draft"
                  class="text-decoration-none"
                  color="text-lighten1"
                  elevation="0"
                  outlined
                  small
                >
                  {{ project.depositsCount.draft }}
                </v-chip>
                <div v-else class="text--secondary mr-2">—</div>
              </v-list-item-title>
            </v-list-item>

          </v-list>

          <v-card-subtitle class="text-right">
            <app-date :value="project.created_at" />
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {mdiAccount, mdiCogOutline, mdiAccountMultiple, mdiFileDocumentMultipleOutline, mdiGraphOutline} from '@mdi/js'
import AppDate from '@/components/AppDate'
import ProjectDialogAddEdit from '@/components/ProjectDialogAddEdit'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProjectsListBusiness',
  components: {AppDate, ProjectDialogAddEdit},
  mixins: [error401handler],
  data () {
    return {
      loading: false,
      reloading: false,
      icons: {
        avatar: mdiAccount,
        edit: mdiCogOutline,
        teammates: mdiAccountMultiple,
        documents: mdiFileDocumentMultipleOutline,
        licenses: mdiGraphOutline,
      }
    }
  },
  computed: {
    ...mapState({
      locale: state => state.config.locale,
      profile: state => state.profile,
      projects: state => state.projectsBusiness?.projects,
      projectsLoaded: state => state.projectsBusiness?.projectsLoaded,
    }),
    allowManageProjects () {
      return this.profile.role === 'manager'
    },
  },
  async mounted () {
    if (this.projectsLoaded) {
      this.reloading = true
    } else {
      this.loading = true
    }
    try {
      await this.loadProjects()
    } catch (e) {
      this.handleError(e)
    } finally {
      this.loading = false
      this.reloading = false
    }
  },
  methods: mapActions({
    loadProjects: 'projectsBusiness/prLoad',
  }),
}
</script>

<style lang="scss" scoped>
.project-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
