<template>
  <div class="input-container">
    <div
      v-if="disabled"
      class="placeholder disabled"
      @click="redirect"
    >
      {{ value || placeholder || '___' }}
    </div>
    <input
      v-else-if="edit"
      ref="input"
      v-model="localValue"
      type="text"
      @blur="stopEdit"
      @keyup.enter="onEnter"
      @keydown.tab="next"
    >
    <div
      v-else
      :class="{error, filled}"
      class="placeholder"
      @click="startEdit"
    >
      {{ value || placeholder || '___' }}
    </div>
    <div
      v-for="(message, i) in invalidFeedback"
      :key="`err-${i}`"
      class="error-text"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import {isEmpty} from 'lodash'

export default {
  name: 'FormDocInput',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: {type: Boolean},
    invalidFeedback: {
      type: Array,
      default () {
        return []
      },
    },
    placeholder: {type: String, default: ''},
    to: {
      type: Object,
      default () {
        return {}
      },
    },
    value: {type: String, default: ''},
  },
  data () {
    return {
      edit: false,
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('change', value)
      },
    },
    filled () {
      return !!this.value
    },
    error () {
      return this.invalidFeedback.length > 0
    },
  },
  methods: {
    startEdit () {
      this.edit = true
      this.$nextTick(() => {
        if (!this.value) {
          this.$refs.input.value = this.placeholder
        }
        this.$refs.input.focus()
        this.$refs.input.select()
      })
    },
    stopEdit () {
      this.edit = false
    },
    onEnter (e) {
      this.localValue = e.target.value
      this.stopEdit()
    },
    redirect () {
      if (this.disabled && !isEmpty(this.to)) {
        this.$router.push(this.to)
      }
    },
    next (e) {
      console.log('tab', e)
      e.preventDefault()
    },
  }
}
</script>

<style lang="scss" scoped>
  .input-container {
    display: inline-flex;
    flex-direction: column;
    line-height: 1.4rem;
    white-space: break-spaces;
  }
  .error-text {
    color: var(--v-error-base) !important;
    font-size: 0.625rem;
    font-family: $body-font-family;
    font-weight: normal;
  }

  input {
    background-color: white;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 1px 0.25rem;
    line-height: 1;
  }
  .placeholder {
    color: #aaa;
    background-color: #fcffaa;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 1px 0.25rem;
    cursor: pointer;
    min-height: 1.4rem;
  }
  .filled {
    color: black;
    background-color: #fcffaa;
    border: none;
  }
  .disabled {
    color: black;
    background-color: #ddd;
    border: none;
  }
  .error {
    color: var(--v-error-base) !important;
    background-color: #ffddcc !important;
    border-color: var(--v-error-base) !important;
  }
</style>
