<template>
  <v-container class="deposit-container pb-10">
    <v-card
      :ripple="false"
      class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
      flat
      outlined
    >
      <v-overlay :value="loading" opacity="0.1" absolute>
        <v-progress-circular color="primary" indeterminate />
      </v-overlay>

      <v-row>
        <v-col lg="6">
          <v-form ref="form" v-model="valid">
            <v-row justify="end">
              <v-col v-if="project.teammates.length" cols="auto">
                <form-contribution-weight-mode
                  :value="contributionWeightMode"
                  @change="changeContributionWeightMode"
                />
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="auto">
                {{ $t('Teammates’ default contribution weights') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p v-if="project.teammates.length === 0" class="grey--text">
                  {{ $t('You have no teammates yet') }}
                </p>
                <v-list-item-group v-else>
                  <v-list-item
                    v-for="(tm, i) in project.teammates"
                    :key="tm.user.pds_id"
                    :ripple="false"
                    three-line
                  >
                    <v-list-item-icon>
                      <app-avatar :image="tm.user.avatar" size="32" />
                    </v-list-item-icon>

                    <v-list-item-content style="flex-grow: 3;align-self: start">
                      <v-list-item-title>
                        {{ tm.user.first_name }}
                        {{ tm.user.last_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ tm.user.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content>
                      <form-contribution-weight
                        :disabled="saving || removing"
                        :feedback="feedback(`teammates.${i}.contributionWeight`)"
                        :mode="contributionWeightMode"
                        :value="tm.user.contributionWeight"
                        @change="inputCW($event, tm)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex align-start justify-end flex-wrap">
                <v-btn
                  :disabled="removing"
                  :loading="saving"
                  :ripple="false"
                  class="btn-bg-gradient"
                  color="primary"
                  elevation="0"
                  rounded
                  @click="submit"
                >
                  {{ $t('Save changes') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import AppAvatar from '@/components/AppAvatar'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import FormContributionWeight from '@/components/FormContributionWeight'
import FormContributionWeightMode from '@/components/FormContributionWeightMode'
import error401handler from '@/mixins/error-401-handler'
import {dateFormat} from '@/util/dates'
import {convertDecimalToFraction} from '@/util/util'

export default {
  name: 'ProjectTeammates',
  components: {
    AppAvatar,
    AppLinkBackToDeposits,
    FormContributionWeight,
    FormContributionWeightMode,
  },
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
  },
  data () {
    return {
      project: {},
      contributionWeightMode: '',
      serverFeedback: {
        name: null
      },
      valid: true,
      loading: false,
      saving: false,
      removing: false,
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      allTeammates: state => state.profile.company?.teammates,
    }),
    ...mapGetters({
      getProjectById: 'projectsBusiness/getProjectById',
    }),
    projectSource () {
      return this.getProjectById(this.projectId)
    },
  },
  async created () {
    if (this.projectSource) {
      this.project = cloneDeep(this.projectSource)

      let cwString = this.project.teammates[0]?.user.contributionWeight
      this.contributionWeightMode = /^\d+\/\d+$/.test(cwString)
        ? 'fraction'
        : 'percent'

      let freeTeammates = this.allTeammates?.filter(tm => {
        return !this.projectSource.teammates.find(a => a.account_id === tm.account_id)
      })?.map(tm => ({
        account_id: tm.account_id,
        user: {
          ...tm.user,
          contributionWeight: '0'
        }
      })) || []

      this.project.teammates = [...this.project.teammates, ...freeTeammates]
      this.project.teammates.sort((tm1, tm2) => {
        return tm2.user.contributionWeight - tm1.user.contributionWeight
      })
    } else {
      this.setError({
        mode: 'global',
        title: this.$t('Project not found'),
      })
    }
  },
  methods: {
    dateFormat,
    ...mapMutations({
      setError: 'error/set',
      showNotify: 'notify/show',
    }),
    ...mapActions({
      updateProject: 'projectsBusiness/prUpdate',
    }),
    inputCW (value, teammate) {
      value = value?.trim()

      if (this.contributionWeightMode === 'percent') {
        value = Number(value)
        value = value === 100 ? '1.0' : (value / 100).toString()
      } else {
        value = value?.replace(/\s+/g, '')
      }

      let i = this.project.teammates.findIndex(tm => {
        return tm.account_id === teammate.account_id
      })
      if (i !== -1) {
        this.project.teammates[i].user.contributionWeight = value
      }
    },
    changeContributionWeightMode (mode) {
      this.contributionWeightMode = mode
      this.project.teammates.forEach((tm, _, arr) => {
        // TODO слишком запутано, переделать
        if (mode === 'percent') {
          let cwHasSlash = /^\d+\/\d+$/.test(tm.user.contributionWeight)
          if (cwHasSlash) {
            let [num, den] = tm.user.contributionWeight.split('/')
            num = Number(num)
            den = Number(den)
            if (isNaN(num) || isNaN(den) || num === 0 || den === 0) {
              tm.user.contributionWeight = '0'
            } else {
              tm.user.contributionWeight = (num / den).toString().substr(0, 4)
            }
          } else {
            tm.user.contributionWeight = '0'
          }
        } else {
          tm.user.contributionWeight =
            convertDecimalToFraction(tm.user.contributionWeight, arr.length)
        }
      })
      this.clearErrors()
    },
    feedback (field) {
      if (Array.isArray(this.serverFeedback[field])) {
        return this.serverFeedback[field].join(' ')
      }
      return this.serverFeedback[field] || ''
    },
    clearErrors () {
      for (let key of Object.keys(this.serverFeedback)) {
        this.serverFeedback[key] = null
      }
    },
    async submit () {
      if (!this.$refs.form.validate()) return
      this.$refs.form.resetValidation()
      this.saving = true
      this.clearErrors()
      let project = {
        id: this.project.id,
        name: this.project.name,
        teammates: this.project.teammates
          .map(tm => ({
            account_id: tm.account_id,
            contributionWeight: tm.user.contributionWeight,
          }))
      }
      try {
        await this.updateProject(project)
        this.showNotify({
          text: this.$t('ProjectUpdated', {title: project.name}),
          color: 'success'
        })
        await this.$router.push({name: 'projects'})
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    },
  },
}
</script>
